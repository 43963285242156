import React, {createContext} from 'react'

const I18nContext = createContext<i18nScope>(window.I18n)

const I18nContextProvider = ({value, children}: {value?: i18nScope; children: React.ReactNode}) => {
  const i18n = value || window.I18n
  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>
}

export {I18nContext, I18nContextProvider}
