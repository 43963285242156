import React from 'react'
import {Root, createRoot} from 'react-dom/client'
import {I18nContextProvider} from '../context/i18n/I18nContext'
import QuickSelectButton from '../packs/quick_select/QuickSelectButton'
import './quick_select.scss'

let root: Root | null = null
window.renderQuickSelect = (
  containerElementId,
  currentUser,
  currentInstituteId,
  currentInstituteType,
  currentInstututeHasStudentSquare: boolean,
  addRecipients,
  maximumRecipientsToAdd,
  selectedRecipients
) => {
  if (root) root.unmount()
  const rootElement = document.getElementById(containerElementId)!
  root = createRoot(rootElement)
  // although this is typescript file, it's used outside, so this can be helpful to avoid hard to debug errors
  if (typeof currentInstututeHasStudentSquare !== 'boolean') {
    throw new Error('currentInstututeHasStudentSquare should be a boolean')
  }
  if (typeof selectedRecipients !== 'function') {
    throw new Error('selectedRecipients should be a function that returns a set of numbers')
  }

  root.render(
    <I18nContextProvider>
      <QuickSelectButton
        currentUser={currentUser}
        currentInstituteId={currentInstituteId}
        currentInstituteType={currentInstituteType}
        currentInstituteHasStudentSquare={currentInstututeHasStudentSquare}
        addRecipients={addRecipients}
        maximumRecipientsToAdd={maximumRecipientsToAdd}
        initialRecipients={selectedRecipients}
      />
    </I18nContextProvider>
  )
}
