import React, {useState, useContext} from 'react'
import {I18nContext} from '../../context/i18n/I18nContext'
import QuickSelect from './QuickSelect'
import type {RecipientToAdd, QuickSelectI18nScope} from './QuickSelect'

interface Props {
  currentUser: {id: number; role: string}
  currentInstituteId: number
  currentInstituteType: string
  currentInstituteHasStudentSquare: boolean
  addRecipients: (recipients: RecipientToAdd[]) => void
  maximumRecipientsToAdd: () => number
  initialRecipients: () => Set<number>
}

const QuickSelectButton = ({
  currentUser,
  currentInstituteId,
  currentInstituteType,
  currentInstituteHasStudentSquare,
  addRecipients,
  maximumRecipientsToAdd,
  initialRecipients,
}: Props) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  const [opened, setOpened] = useState(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)

  return (
    <>
      <button type="button" onClick={open} className="quick-select-button">
        {i18nQS.add_by_quick_select_button}
      </button>
      {opened && (
        <QuickSelect
          closeModal={close}
          currentUser={currentUser}
          currentInstituteId={currentInstituteId}
          currentInstituteType={currentInstituteType}
          currentInstituteHasStudentSquare={currentInstituteHasStudentSquare}
          onAddRecipientsClick={addRecipients}
          maximumRecipientsToAdd={maximumRecipientsToAdd}
          initialRecipients={initialRecipients}
        />
      )}
    </>
  )
}

export default QuickSelectButton
